import {
    nullable,
    onlyMayusText,
    onlyAlphanumeric,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength } from "vuelidate/lib/validators";

    const isValidFile = (value) => {
        if(value === '' || value === null || value === undefined){
            return true;
        }
        if(!value){
            return false;
        }
        if(value.type)
          return value.type.startsWith('image');
        else
            return (value != '');
    };

    export default (Bin) => {
        let Required = false;
        if (Bin) {
            Required = { required } 
        }
        return {
            formImplemento: {
                IdGpoImplemento:{
                    required,
                },
                NbImplemento:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(2),
                    maxLength: maxLength(250),
                },
                DsImplemento:{
                    onlyAlphanumeric,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                },
                FgActImplemento:{
                    required
                },
                RutaImplemento: { },
                BinClient: Required,
            },
            formGpoImplemento: {
                NbGpoImplementoEn:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(3),
                    maxLength: maxLength(250),
                },
                NbGpoImplementoEs:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(3),
                    maxLength: maxLength(250),
                },
                FgActGpoImplemento:{
                    required
                },
            },
        }
    }
    
    